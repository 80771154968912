import React from 'react'
import { Link } from 'gatsby'

// import icons
import { FaTwitter } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import { FaGooglePlus } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'
import { FaPinterest } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
// import react-custom-share components
import { ShareButtonRoundSquare, ShareBlockStandard } from 'react-custom-share'

import Title from '../components/shared/Title'
import {
  Container,
  Row,
  Col,
  Badge,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'
import Layout from '../components/layout'
import Icons from '../components/shared/Icons'
import Seo from '../components/shared/Seo'

class NewsBlogs extends React.Component {
  render() {
    const context = this.props.pathContext
    const group = this.props.pathContext.group
    let paginate = []

    for (let i = 1; i <= context.pageCount; i++) {
      paginate.push(i)
    }

    const shareBlockProps = {
      button: ShareButtonRoundSquare,
      buttons: [
        { network: 'Twitter', icon: FaTwitter },
        { network: 'Facebook', icon: FaFacebook },
        { network: 'GooglePlus', icon: FaGooglePlus },
        { network: 'Email', icon: FaEnvelope },
        { network: 'Linkedin', icon: FaLinkedin },
      ],
    }

    return (
      <Layout>
        <Seo
          title="News & Blogs"
          description=""
          url="https://proteo.co.uk/news-blogs/"
        />
        <Container>
          {group.map(({ node }, i) => (
            <Row
              className="rounded py-3 my-5  text-white"
              style={{
                maxWidth: '100%',
                margin: '0 auto',
                background: 'url("/images/dotted-map.png")',
                backgroundColor: '#171B26',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
              }}
              key={i}
            >
              <Col md="4" className="position-relative  ">
                <Link to={`blog/${node.slug}`}>
                  <div
                    className="w-100 border-40"
                    title={
                      (node.featuredImage && node.featuredImage.title) || ''
                    }
                    style={{
                      height: '15em',
                      maxHeight: '100%',
                      margin: '0 auto 1em auto',
                      background: `url(${node.featuredImage.file.url})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                </Link>
                <Badge
                  color="gold"
                  className="text-white w-75 d-block py-2  mb-2 mx-auto  "
                >
                  {node.published}
                </Badge>
              </Col>
              <Col md="8" className="pt-3">
                <div>
                  <h3 className="text-gold">
                    <Title to={`blog/${node.slug}`}>{node.title}</Title>
                  </h3>
                  <p className="text-justify">{node.excerpt.text}</p>
                  <Link
                    to={`blog/${node.slug}`}
                    className="btn primary-btn-gradient text-white py-2 px-5"
                  >
                    Read More
                  </Link>
                  <ShareBlockStandard
                    className="blog-listing-shares"
                    {...shareBlockProps}
                    url={`https://proteo.co.uk/blog/${node.slug}`}
                    text={node.title}
                    longtext={node.excerpt.text}
                  />
                </div>
              </Col>
            </Row>
          ))}
          {group.length !== 1 && (
            <Pagination
              className="d-flex justify-content-center mb-4"
              aria-label="Page navigation example"
            >
              <PaginationItem disabled={context.first}>
                <PaginationLink
                  tag={Link}
                  previous
                  to={`${context.pathPrefix}/${(context.index - 1).toString()}`}
                />
              </PaginationItem>
              {paginate.map(item => (
                <PaginationItem active={item === context.index} key={item}>
                  <PaginationLink
                    tag={Link}
                    to={`${context.pathPrefix}/${
                      item === 1 ? '' : item.toString()
                    }`}
                  >
                    {item}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={context.last}>
                <PaginationLink
                  tag={Link}
                  next
                  to={`${context.pathPrefix}/${(context.index + 1).toString()}`}
                />
              </PaginationItem>
            </Pagination>
          )}
        </Container>
        <Icons />
      </Layout>
    )
  }
}

export default NewsBlogs
